var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"main",class:{
      'hide-navbar': _vm.hideNavbar,
     },style:({
        'margin-top': _vm.marginTop + 'px',
      })},[_c('div',{class:{
        'container is-max-widescreen': !_vm.isGapless
    },staticStyle:{"min-height":"60vh"},style:({
        'padding-top': _vm.isGapless ? 0 : '2.25rem',
        'padding-bottom': _vm.isGapless ? 0 : '1.5rem',
       }),attrs:{"id":"main"}},[(!_vm.hideNavbar)?[(!_vm.embed && _vm.navbar)?_c('sg-navbar'):_vm._e()]:_vm._e(),_c('div',{staticClass:"columns is-tablet",class:{
        'm-0': _vm.isGapless,
      }},[_c('div',{staticClass:"column is-12",class:{
        'p-0': _vm.isGapless,
      }},[_c('router-view')],1)])],2)])}
var staticRenderFns = []

export { render, staticRenderFns }