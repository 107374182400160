<template>
  <div class="main"
       :class="{
        'hide-navbar': hideNavbar,
       }"
       :style="{
          'margin-top': marginTop + 'px',
        }"
        >
    <div :class="{
          'container is-max-widescreen': !isGapless
      }"
         :style="{
          'padding-top': isGapless ? 0 : '2.25rem',
          'padding-bottom': isGapless ? 0 : '1.5rem',
         }"
         id="main" style="min-height: 60vh;">
      <template v-if="!hideNavbar">
        <sg-navbar v-if="!embed && navbar"/>
      </template>
      <div class="columns is-tablet" :class="{
          'm-0': isGapless,
        }">
        <div class="column is-12" :class="{
          'p-0': isGapless,
        }">
          <router-view/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SgNavbar from "@/components/Sg/SgNavbar";
import {mapState} from "vuex";
// import SgFooter from "@/components/Sg/SgFooter";

export default {
  name: 'Main',
  components: {
    // SgFooter,
    SgNavbar,
  },
  data: () => {
    return {
      marginTop: 0,
    }
  },
  computed: {
    sidebar() {
      return this.$store.state.sidebar
    },
    loggedIn() {
      return this.$store.getters["user/loggedIn"]
    },
    embed() {
      return this.$route.query.output === 'embed'
    },
    navbar() {
      return this.$route.meta.navbar !== false
    },
    isGapless() {
      return this.$route.meta.isGapless
    },
    hideNavbar() {
      return this.$route.meta.hideNavbar === true
    },
    ...mapState([
      'update_navbar',
    ]),
  },
  watch: {
    loggedIn(value) {
      if (value) this.$store.dispatch('farm/getWarehouseCategory')
    },
    update_navbar(value) {
      if (value) this.handleChangeNavbar()
    },
  },
  created() {
    if (this.loggedIn) this.$store.dispatch('farm/getWarehouseCategory')
  },
  mounted() {
    this.handleChangeNavbar()
  },
  methods: {
    handleChangeNavbar() {
      let navbar = document.querySelector('.navbar')
      let topOverlay = document.querySelector('.sg-top-overlay')

      let marginTop = navbar ? navbar.offsetHeight : 0
      if (topOverlay) marginTop += topOverlay.offsetHeight
      this.marginTop = marginTop

      this.$store.commit('UPDATE_NAVBAR', false)
      this.$store.commit('UPDATE_NAVBAR_MARGIN', this.marginTop)
    }
  }
}
</script>

<style scoped lang="scss">
.main {
  margin-top: calc(76px + 79px);
  @media (max-width: 768px) {
    margin-top: calc(70px + 121px);
  }
}
</style>
