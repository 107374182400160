<template>
  <div>
<!--    <b-navbar wrapper-class="container is-max-widescreen" :class="{'is-shadowless': !hideOverlay}" fixed-top>-->
    <b-navbar wrapper-class="container is-max-widescreen" :class="{'is-shadowless': true}" fixed-top>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/farm/' + farm_id }">
          <img
              src="/img/assets/logo.png"
              alt="Sgara Logo">
          <span class="navbar-brand-title has-text-dark">Sgara<span class="has-text-primary">Book</span></span>
        </b-navbar-item>
      </template>

      <template slot="end">
        <!--
                <b-dropdown position="is-bottom-left" aria-role="list" class="is-paddingless dropdown-notification">
                    <span class="navbar-item navbar-link" slot="trigger" role="button">
                      <b-icon icon="bell-outline"></b-icon>
                    </span>

                  <b-dropdown-item
                      aria-role="menu-item"
                      :focusable="false"
                      custom
                      paddingless>
                    <sg-tab class="border-top-0 px-4" v-model="activeNotif" :items="['Activity', 'Alert']"/>
                    <div>
                      <div class="is-flex">
                        <sg-scroll class="p-3">
                          <div class="is-flex" v-for="k in 6" :key="k">
                            <img
                                class="navbar-profile-img mr-4"
                                src="/img/assets/profile.jpg"
                                alt="Profile"
                            />
                            <div>
                              <h4 class="is-size-6 has-text-weight-bold">Irfan Fakhri</h4>
                              <p>{{ $t('farm.pond') }} A1 from <span class="has-text-weight-bold">Block Dago has Updated its Bacteria section of water quality</span>
                              </p>
                              <div class="is-flex is-justify-content-space-between mt-5">
                                <span class="has-text-grey">11 Aug</span>
                                <span class="has-text-success">Water Quality</span>
                              </div>
                              <div class="dropdown-divider"></div>
                            </div>
                          </div>
                        </sg-scroll>
                      </div>
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item paddingless>
                    <b-button tag="router-link" to="/notifications" type="is-text" class="btn-action has-text-info" outlined
                              expanded>
                      See All Activity
                    </b-button>
                  </b-dropdown-item>
                </b-dropdown>
        -->
        <!--        <b-navbar-item href="#" class="navbar-link is-arrowless">-->
        <!--          <b-icon icon="dots-horizontal"></b-icon>-->
        <!--        </b-navbar-item>-->

        <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
          <div slot="trigger" slot-scope="{ active }" class="is-flex is-align-items-center navbar-item navbar-link">
            <img
                class="navbar-profile-img mr-4"
                :src="user && user.photo ? user.photo : '/img/assets/profile.jpg'"
                alt="Profile"
            />
            {{ user ? user.username : '-' }}
            <b-icon class="ml-4" :icon="active ? 'chevron-up' : 'chevron-down'"></b-icon>
          </div>

          <b-dropdown-item aria-role="listitem" has-link>
            <router-link to="/profile">Profile</router-link>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="logout">Logout</b-dropdown-item>
        </b-dropdown>

        <b-dropdown aria-role="list" position="is-bottom-left" append-to-body>
          <div slot="trigger" class="is-flex is-align-items-center navbar-item navbar-link">
            <img :src="`/img/assets/${lang}.png`" :alt="lang" class="flag">
          </div>

          <b-dropdown-item aria-role="listitem" @click="changeLocale('id')">
            <div class="is-flex is-align-items-center">
              <img src="/img/assets/id.png" alt="id" class="flag">
              <span class="ml-2">Indonesia</span>
            </div>
          </b-dropdown-item>
          <b-dropdown-item aria-role="listitem" @click="changeLocale('en')">
            <div class="is-flex is-align-items-center">
              <img src="/img/assets/en.png" alt="en" class="flag">
              <span class="ml-2">English</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </template>
    </b-navbar>
  </div>
</template>

<script>
// import SgTab from "@/components/Sg/SgTab";
// import SgScroll from "@/components/Sg/SgScroll";
import i18n from "@/i18n";
import {mapGetters} from "vuex";
import {firebaseLogout} from "@/firebase";

export default {
  name: "SgNavbar",
  // components: {SgScroll, SgTab},
  data: () => {
    return {
      activeNotif: 'Activity'
    }
  },
  computed: {
    ...mapGetters('user', [
      'user',
    ]),

    hideOverlay() {
      return this.$route.meta.overlay === false
    },
    notification: {
      set(value) {
        this.$store.state.notification = value
      },
      get() {
        return this.$store.state.notification
      }
    },
    selectedFarm() {
      return this.$store.state.farm.selectedFarm
    },
    farm_id() {
      return this.selectedFarm.farm_id || ''
    },
    lang() {
      return i18n.locale
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('user/logout')
      firebaseLogout()
    },
    changeLocale(lang) {
      this.$loading()
      this.$store.dispatch('setting/updateLocale', lang).then(() => {
        location.reload()
      })
    }
  },
}
</script>

<style lang="scss" scoped>
.flag {
  height: 16px !important;
  border: 1px solid rgba(0, 0, 0, .09);
}
</style>
